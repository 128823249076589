.container {
    padding: 0 2rem;
}

.main {
    min-height: 70vh;
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-family: system, -apple-system, sans-serif;
}

.main p {
    line-height: 1.5rem;
}

.main h2 {
    margin: 2em 0 1em;
}

.footer {
    display: flex;
    padding: 1rem 0;
    align-content: flex-end;
    margin: auto 0 0;
}

.footer a {
    color: #000;
    text-decoration: underline;
}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
}

.title {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.title,
.description {
    text-align: center;
}

.description {
    margin: 4rem 0;
    line-height: 1.5;
    font-size: 1.5rem;
}

.code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
        DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}
.btn {
    display: block;
}
.container {
    width: 600px;
    margin: 5em auto;
}
.yellow {
    background-color: rgba(255, 245, 147, 0.81);
    color: inherit;
    padding: 5px;
    border-radius: 5px;
}
section.disclaimer {
    background-color: inherit;
    font-size: 0.8em;
}

.list {
    margin: 0;
    padding: 0 10px;
}

.list li {
    margin-bottom: 1rem;
    margin-left: 10px;
    line-height: 1.4em;
}

.list a {
    text-decoration: underline;
}

.link {
    border-bottom: 1px solid black;
    font-weight: 600;
}

.italicheader {
    font-style: italic;
}

.links p {
    color: #312f8e;
    font-size: 1.25em;
    padding: 0.5em;
    overflow: hidden;
    border-radius: 4px;
    background-color: rgba(247, 247, 247, 0.82);
}

.formHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.btn {
    border-radius: 3px;
    border: 3px solid #007dc1;
    cursor: pointer;
    color: #007dc1;
    font-size: 16px;
    padding: 8px 15px;
    background: none;
}

.bookmarkLink {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    font-size: 20px;
}

.bookmarkLink li {
    margin-bottom: 1rem;
    line-height: 1.4em;
    border-left: 5px solid #007dc1;
    padding-left: 10px;
    width: 95%;
}

.emailInput {
    font-size: 16px;
    padding: 8px;
}

.reminder {
    background-color: #fafafa;
    text-align: center;
    font-style: italic;
    margin: 10px;
    border-radius: 5px;
    padding: 0.5em;
}
